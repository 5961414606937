#top-overall {
  .timePeriodActive {
    background-color: #e31f26;
    border-color: #e31f26;
    color: #fff;
  }
  .ant-statistic {
    min-height: 87px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    &>div {
      flex: 1;
    }
    .ant-statistic-title {
      font-weight: 500;
      color: #292929;
    }
    .ant-statistic-content-value {
      color: #292929;
      font-size: 20px;
      font-weight: 600;
    }
  }
}